import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Observable, of, Subject } from "rxjs";
import { ConstantsService } from "../constants-service/constants.service";
import { AuthenticationService } from "../authentication-service/authentication.service";
import { LocalStorageService } from "../local-storage-service/local-storage.service";
import * as Enums from "../../enums/enums";
import { UserService } from "../user-service/user.service";
import { TranslateService } from "@ngx-translate/core";
import {BulletinModel, BulletinModelAsJSON} from "../../models/bulletin.model";
import {map} from "rxjs/operators";
import {BulletinLockModel} from "../../models/bulletin-lock.model";

export interface TranslationRecord {
  languageCode: string;
  status: string;
}

@Injectable()
export class TranslationsService {
  private activeDate: [Date, Date];
  private isEditable: boolean;
  private isReadOnly: boolean;
  public lockedBulletins: Map<string, BulletinLockModel>;

  public statusMap: Map<string, Map<number, Enums.BulletinStatus>>;
  public translationStatusMap: Map<string, Map<number, TranslationRecord[]>>;
  public dates: [Date, Date][];

  constructor(
    public http: HttpClient,
    private constantsService: ConstantsService,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private userService: UserService
  ) {}

  init({ days } = { days: 10 }) {
    this.dates = [];
    this.isEditable = false;
    this.isReadOnly = false;

    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 3);
    endDate.setHours(0, 0, 0, 0);

    for (let i = 0; i <= days; i++) {
      const date = new Date(endDate.valueOf());
      date.setDate(endDate.getDate() - i);
      this.dates.push(this.getValidFromUntil(date));
    }

    this.loadStatus();
  }

  public loadStatus() {
    const startDate = this.dates[this.dates.length - 1];
    const endDate = this.dates[0];
    this.statusMap = new Map<string, Map<number, Enums.BulletinStatus>>();
    this.translationStatusMap = new Map<string, Map<number, TranslationRecord[]>>();
    this.getStatus(this.authenticationService.getActiveRegionId(), startDate, endDate).subscribe(
      (data) => {
        let map = new Map<number, Enums.BulletinStatus>();
        let translationMap = new Map<number, TranslationRecord[]>();
        for (let i = (data as any).length - 1; i >= 0; i--) {
          map.set(Date.parse((data as any)[i].date), Enums.BulletinStatus[<string>(data as any)[i].status]);
          translationMap.set(Date.parse((data as any)[i].date), (data as any)[i].translationStatus as TranslationRecord[]);
        }
        this.statusMap.set(this.authenticationService.getActiveRegionId(), map);
        this.translationStatusMap.set(this.authenticationService.getActiveRegionId(), translationMap);
      },
      () => {
        console.error("Status {} could not be loaded!", this.authenticationService.getActiveRegionId());
      },
    );
  }

  getValidFromUntil(date: Date): [Date, Date] {
    const validFrom = new Date(date);
    validFrom.setTime(validFrom.getTime() - 7 * 60 * 60 * 1000);
    const validUntil = new Date(date);
    validUntil.setTime(validUntil.getTime() + 17 * 60 * 60 * 1000);
    return [validFrom, validUntil];
  }

  getIsEditable(): boolean {
    return this.isEditable && !this.isReadOnly;
  }

  setIsEditable(isEditable: boolean) {
    this.isEditable = isEditable;
  }

  getIsReadOnly(): boolean {
    return this.isReadOnly;
  }

  setIsReadOnly(isReadOnly: boolean) {
    this.isReadOnly = isReadOnly;
  }

  getActiveDate(): [Date, Date] {
    return this.activeDate;
  }

  setActiveDate(date: [Date, Date]) {
    this.activeDate = date;
  }

  /**
   * Returns a date that's offset from the activeDate by a given amount.
   *
   * @param offset - Number of days to offset. Can be negative (future) or positive (past).
   * @returns Date offset from the activeDate or null if not found or out of bounds.
   */
  private getDateOffset(offset: number): [Date, Date] | null {
    if (!this.activeDate) {
      return null;
    }

    const index = this.dates.findIndex((d) => d[0].getTime() === this.activeDate[0].getTime());

    if (index === -1 || index + offset < 0 || index + offset >= this.dates.length) {
      return null;
    }

    return this.dates[index + offset];
  }

  getNextDate(): [Date, Date] | null {
    return this.getDateOffset(-1);
  }

  getPreviousDate(): [Date, Date] | null {
    return this.getDateOffset(1);
  }

  getUserRegionStatus(date: [Date, Date]): Enums.BulletinStatus {
    const region = this.authenticationService.getActiveRegionId();
    const regionStatusMap = this.statusMap.get(region);
    if (regionStatusMap) return regionStatusMap.get(date[0].getTime());
    else return Enums.BulletinStatus.missing;
  }

  setUserRegionStatus(date: [Date, Date], status: Enums.BulletinStatus) {
    const region = this.authenticationService.getActiveRegionId();
    this.statusMap.get(region).set(date[0].getTime(), status);
  }

  getStatus(
    region: string,
    startDate: [Date, Date],
    endDate: [Date, Date],
  ): Observable<{ date: string; status: keyof typeof Enums.BulletinStatus }[]> {
    const url =
      this.constantsService.getServerUrl() +
      "translations/status/internal?" +
      this.constantsService
        .createSearchParams([
          ["startDate", this.constantsService.getISOStringWithTimezoneOffset(startDate[0])],
          ["endDate", this.constantsService.getISOStringWithTimezoneOffset(endDate[0])],
          ["region", region],
        ])
        .toString();
    const headers = this.authenticationService.newAuthHeader();
    return this.http.get<any>(url, { headers });
  }

  getPublicationStatus(region: string, date: [Date, Date]) {
    const url =
      this.constantsService.getServerUrl() +
      "bulletins/status/publication?" +
      this.constantsService
        .createSearchParams([
          ["date", this.constantsService.getISOStringWithTimezoneOffset(date[0])],
          ["region", region],
        ])
        .toString();
    const headers = this.authenticationService.newAuthHeader();
    return this.http.get(url, { headers });
  }

  loadTranslations(
    date: [Date, Date],
    regions: string[],
    etag?: string,
  ): Observable<{ bulletins: BulletinModelAsJSON[]; etag: string | null }> {
    return this.loadTranslationsFromServer(date, regions, etag);
  }

  loadTranslationsFromServer(
    date: [Date, Date],
    regions: string[],
    etag?: string,
  ): Observable<{ bulletins: BulletinModelAsJSON[]; etag: string | null }> {
    let url =
      this.constantsService.getServerUrl() +
      "translations/edit?" +
      this.constantsService
        .createSearchParams([
          ["date", this.constantsService.getISOStringWithTimezoneOffset(date[0])],
          ["regions", regions],
        ])
        .toString();
    const headers = this.authenticationService.newAuthHeader();
    if (etag) headers.set("If-None-Match", etag);
    return this.http
      .get<BulletinModelAsJSON[]>(url, { headers, observe: "response" })
      .pipe(map((response) => ({ bulletins: response.body, etag: response.headers.get("ETag") })));
  }

  isLocked(bulletinId: string) {
    if (
      this.lockedBulletins.has(bulletinId) &&
      this.lockedBulletins.get(bulletinId).getUserEmail() !== this.authenticationService.getEmail()
    ) {
      return true;
    }
    return false;
  }

  updateBulletin(bulletin: BulletinModel, date: [Date, Date]): Observable<BulletinModelAsJSON[]> {
    // check if bulletin has ID
    const url =
      this.constantsService.getServerUrl() +
      "translations/" +
      bulletin.getId() +
      "?" +
      this.constantsService
        .createSearchParams([
          ["date", this.constantsService.getISOStringWithTimezoneOffset(date[0])],
          ["region", this.authenticationService.getActiveRegionId()],
        ])
        .toString();
    const headers = this.authenticationService.newAuthHeader();
    const body = JSON.stringify(bulletin.toJson());
    return this.http.post<BulletinModelAsJSON[]>(url, body, { headers });
  }

  submitTranslation(date: [Date, Date], region: string, lang: string) {
    const url =
      this.constantsService.getServerUrl() +
      "translations/submit?" +
      this.constantsService
        .createSearchParams([
          ["date", this.constantsService.getISOStringWithTimezoneOffset(date[0])],
          ["region", region],
          ["lang", lang],
        ])
        .toString();
    const headers = this.authenticationService.newAuthHeader();
    const body = JSON.stringify("");
    return this.http.post<{}>(url, body, { headers });
  }
}
